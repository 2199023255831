var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("LandingHeader"),
      _c("ContactHero"),
      _c("div", { ref: "contactSection", staticClass: "contact-section" }, [
        _c("div", { ref: "contactCol", staticClass: "contact-col" }, [
          _vm._m(0),
          _c("div", { staticClass: "second-col" }, [
            _c("form", [
              _c("div", [
                _vm._m(1),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.name,
                      expression: "form.name",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.form.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "name", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", [
                _vm._m(2),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.email,
                      expression: "form.email",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "email" },
                  domProps: { value: _vm.form.email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "email", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", [
                _vm._m(3),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.message,
                      expression: "form.message",
                    },
                  ],
                  attrs: { cols: "30", rows: "5" },
                  domProps: { value: _vm.form.message },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "message", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "form-submit-button-box mt-2" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-primary btn-wide kt-font-xl kt-font-bolder",
                    class: {
                      "kt-spinner kt-spinner--sm kt-spinner--light": _vm.saving,
                    },
                    attrs: {
                      type: "button",
                      disabled: _vm.saving || !_vm.canSubmitForm,
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.submitForm.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(" Submit ")]
                ),
              ]),
            ]),
            _vm._m(4),
          ]),
        ]),
      ]),
      _c("LandingFooter"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "first-col" }, [
      _c("h2", [_vm._v("Reach Out")]),
      _c("p", [_vm._v("Fill out the form to start using SyncGrades!")]),
      _c("div", { staticClass: "contact-info" }, [
        _c("div", { staticClass: "contact-info-email" }, [
          _c("span", { staticClass: "kt-badge kt-badge--brand kt-badge--xl" }, [
            _c("i", { staticClass: "fa fa-envelope fa-2x" }),
          ]),
          _c(
            "a",
            {
              staticClass: "contact-text",
              attrs: { href: "mailto:support@syncgrades.com" },
            },
            [_vm._v("support@syncgrades.com")]
          ),
        ]),
        _c("div", { staticClass: "contact-info-address" }, [
          _c("span", { staticClass: "kt-badge kt-badge--brand kt-badge--xl" }, [
            _c("i", { staticClass: "fa fa-map-pin fa-2x" }),
          ]),
          _c("a", { staticClass: "contact-text" }, [
            _vm._v("112 W. 34th Street, Suite 17059 New York, NY 10120"),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("i", { staticClass: "fa fa-user" }),
      _vm._v("Full Name "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("i", { staticClass: "fa fa-envelope" }),
      _vm._v("Email "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("i", { staticClass: "fa fa-comment-alt" }),
      _vm._v("Your Message "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "extra-details" }, [
      _c("p", [
        _vm._v(" Already a SyncGrades User? Visit "),
        _c("a", { attrs: { href: "/support" } }, [_vm._v("Support")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
<div>
    <!-- header new -->
    <LandingHeader />

    <!-- hero section -->
    <ContactHero />

    <!-- <Contact form /> -->
    <div ref="contactSection" class="contact-section">
        <div ref="contactCol" class="contact-col">
            <div class="first-col">
                <h2>Reach Out</h2>
                <p>Fill out the form to start using SyncGrades!</p>

                <div class="contact-info">
                    <div class="contact-info-email">
                        <span class="kt-badge kt-badge--brand kt-badge--xl"><i class="fa fa-envelope fa-2x" /></span>
                        <a
                            class="contact-text"
                            href="mailto:support@syncgrades.com"
                        >support@syncgrades.com</a>
                    </div>
                    <div class="contact-info-address">
                        <span class="kt-badge kt-badge--brand kt-badge--xl"><i class="fa fa-map-pin fa-2x" /></span>
                        <a class="contact-text">112 W. 34th Street, Suite 17059 New York, NY 10120</a>
                    </div>
                </div>
            </div>
            <div class="second-col">
                <form>
                    <div>
                        <label>
                            <i class="fa fa-user" />Full Name
                        </label>
                        <input
                            v-model="form.name"
                            type="text"
                            class="form-control"
                        >
                    </div>
                    <div>
                        <label>
                            <i class="fa fa-envelope" />Email
                        </label>
                        <input
                            v-model="form.email"
                            type="email"
                            class="form-control"
                        >
                    </div>
                    <div>
                        <label>
                            <i class="fa fa-comment-alt" />Your Message
                        </label>
                        <textarea
                            v-model="form.message"
                            cols="30"
                            rows="5"
                        />
                    </div>
                    <div class="form-submit-button-box mt-2">
                        <button
                            type="button"
                            class="btn btn-primary btn-wide kt-font-xl kt-font-bolder"
                            :class="{'kt-spinner kt-spinner--sm kt-spinner--light': saving}"
                            :disabled="saving || !canSubmitForm"
                            @click.stop.prevent="submitForm"
                        >
                            Submit
                        </button>
                    </div>
                </form>
                <div class="extra-details">
                    <p>
                        Already a SyncGrades User? Visit <a href="/support">Support</a>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <!-- footer-section -->
    <LandingFooter />
</div>
</template>

<script>
import gsap from 'gsap';
import LandingHeader from '../components/landing/LandingHeader.vue';
import ContactHero from '../components/landing/ContactHero.vue';
import LandingFooter from '../components/landing/LandingFooter.vue';
import * as network from '../network';

export default {
    name: 'ContactUs',
    components: {
        LandingHeader,
        ContactHero,
        LandingFooter,
    },
    data() {
        return {
            form: {
                name: null,
                email: null,
                message: null,
            },
            recentlySubmitted: false,
            saving: false,
        };
    },
    computed: {
        canSubmitForm() {
            const { form } = this;
            return form.name && form.email && form.message;
        },
    },
    mounted() {
        // Intersection Observer to detect when element enters viewport
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Trigger animation when element enters viewport
                    this.animateHeading();
                    observer.unobserve(entry.target);
                }
            });
        });

        // Observe the hero section
        observer.observe(this.$refs.contactSection);
    },
    methods: {
        animateHeading() {
            // GSAP Animation
            gsap.from(this.$refs.contactCol, {
                duration: 1,
                y: 150,
                opacity: 0,
                delay: 0.5,
                ease: 'power1.inOut',
            });
        },
        submitForm() {
            const v = this;
            if (v.recentlySubmitted) {
                return v.showError('We got your last form! Please wait a moment before submitting the form again.');
            }
            if (v.saving || !v.canSubmitForm) return;
            v.saving = true;

            const { form } = v;
            const { name, email, message } = form;

            const body = `From ${name}\n${email}\n\nMessage: ${message}`;

            network.landing.submitContactForm({ body: { email, message: body } }, (err) => {
                v.saving = false;
                if (err) return v.showError(err);

                v.showNotification('Your message has been sent! We will get back to you shortly.');
                v.form = { name: null, email: null, message: null };
                v.recentlySubmitted = true;
                setTimeout(() => {
                    v.recentlySubmitted = false;
                }, 15000);
            });
        },
    },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;

  img {
    width: 40px !important;
    background: #2b304e;
    border-radius: 10px;
  }
}

.contact-section {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: row;
  width: 100%;

  .contact-col {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    padding: 70px 10px;

    .first-col {
      display: flex;
      justify-content: flex-start;
      /* align-items: start; */
      flex-direction: column;
      width: 50%;
      padding: 30px;

      h2 {
        font-size: 3.5em;
        font-weight: 700;
        margin-bottom: 5px;
      }

      p {
        font-size: 1.8em;
        font-weight: 500;
        color: #292929;
        margin-bottom: 20px;
      }

      .contact-info {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 30px;

        .contact-info-email {
          display: flex;
          align-items: center;
          gap: 10px;
          .icon-box {
            width: 70px;
            height: 70px;
            background: #dfe4ff;
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            img {
              width: 50%;
            }
          }
          .contact-text {
            font-size: 1.5em;
            font-weight: 500;
            color: #292929;
          }
        }

        .contact-info-address {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-top: 10px;

          .icon-box {
            width: 90px;
            height: 70px;
            background: #dfe4ff;
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            img {
              width: 50%;
            }
          }
          .contact-text {
            font-size: 1.5em;
            font-weight: 500;
            color: #292929;
          }
        }
      }
    }

    .second-col {
      display: flex;
      justify-content: center;
      /* align-items: center; */
      flex-direction: column;
      width: 50%;

      form {
        background-color: #dfe4ff;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
        padding: 40px;
        border-radius: 10px;
        width: 100%;
        margin-bottom: 20px;
        align-items: center;

        div label {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 1.3em;
          font-weight: 500;
          color: #2b304e;
          margin-top: 20px;

          img {
            width: 20px;
          }
        }
        input,
        textarea {
          width: 100%;
          border-radius: 5px;
          background: white;
          padding: 10px;
          outline: none;
          border: none;
        }

        input[type="submit"] {
          width: 200px;
          padding: 10px 40px;
          background: linear-gradient(to right, #1b84ff, #56a3fe);
          box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
          color: white;
          font-size: 1.3em;
          font-weight: 500;
          border-radius: 1em;
          margin-top: 10px;
          cursor: pointer;
          transition: 0.3s;
        }

        .form-submit-button-box {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .extra-details {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 20px;

        p {
          font-size: 1.5em;
          font-weight: 500;
          color: #292929;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .contact-section {
    padding: 50px 10px;
    .contact-col {
      width: 100%;
      padding: 20px;

      .first-col {
        padding: 20px;
        h2 {
          font-size: 2.5em;
        }

        p {
          font-size: 1.2em;
        }
      }

      .second-col {
        width: 50%;
        img {
          width: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .contact-section {
    justify-content: center;
    align-items: center;
    padding: 10px;
    .contact-col {
      flex-direction: column;
      .first-col {
        width: 100%;
        .contact-info {
          .contact-info-address {
            display: flex;
            align-items: center;
            gap: 10px;
            .icon-box {
              width: 72px;
              height: 70px;
            }
          }
        }
      }
      .second-col {
        margin: 30px 0;
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 425px) {
  .contact-section {
    padding: 10px;
    .contact-col {
      padding: 5px;
      .first-col {
        padding: 20px 10px;

        .contact-info {
          .contact-info-address {
            display: flex;
            align-items: center;
            gap: 10px;
            .icon-box {
              width: 132px;
              height: 70px;
            }
          }
        }
      }
      .second-col {
        .extra-details {
          padding: 10px 20px;
          p {
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
